//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : api.js
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (11 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240711     Jecyline     create api.jsx
//* 20240724     Jecyline     remove alert message
//* 20240805     Jecyline     set feedbackModalShown to localStorage
//* 20240806     Jecyline     remove item localStorage refresh_token and feedbackModalShown during token refresh
//* 20241003     Jecyline     clear all reducer, localstorage and sessionstorage when token expired
//
//***************************************************************************/
import axios from 'axios';
import { api_url } from "../base_urls";

// Create an Axios instance with the base URL for your API
const api = axios.create({
    baseURL: api_url,  // Base URL for your API
    headers: {
        'Content-Type': 'application/json',
        // You can also add authorization headers here if needed
        // 'Authorization': `Bearer ${yourToken}`
    }
});

// Add an interceptor to handle errors globally
api.interceptors.request.use(
    config => {
        const access_token = localStorage.getItem('access_token');  // Add token to request headers if available
        // const token_type = localStorage.getItem('token_type');  // Add token to request headers if available
        if (access_token) {
            config.headers['Authorization'] = `Bearer ${access_token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const setupInterceptors = (navigate, dispatch) => {
  api.interceptors.response.use(
    response => response,
    async (error) => {
      if (error.response && error.response.status === 401) {

        const refreshToken = localStorage.getItem('refresh_token');
        const tokenType = localStorage.getItem('token_type');
  
        if (refreshToken && tokenType) {
    
          try {
            // Attempt to refresh the token
            const response = await axios.post(api_url + '/refresh-token', {
                refresh_token: refreshToken,
            });

            const { access_token, token_type, expires_in } = response.data;
            
            // Update local storage with new token data
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('token_type', token_type);
            localStorage.setItem('expires_in', expires_in);

            // Retry the failed request with the new token
            error.config.headers['Authorization'] = `${token_type} ${access_token}`;
            return axios.request(error.config);
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            
            // localStorage.removeItem('access_token');
            // localStorage.removeItem('token_type');
            // localStorage.removeItem('expires_in');
            // localStorage.removeItem('refresh_token');
            // localStorage.removeItem('feedbackModalShown');

            dispatch({ type: 'RESET_ALL' });
            localStorage.clear();  // Clears all local storage
            sessionStorage.clear();  // Clears session storage
            // alert('Token refresh failed. Please log in again.');
            navigate('/');
          }
        } else {
          console.error('Invalid token. Please log in again.');

          // localStorage.removeItem('access_token');
          // localStorage.removeItem('token_type');
          // localStorage.removeItem('expires_in');
          // localStorage.removeItem('refresh_token');
          // localStorage.removeItem('feedbackModalShown');

          dispatch({ type: 'RESET_ALL' });
          localStorage.clear();  // Clears all local storage
          sessionStorage.clear();  // Clears session storage
          // alert('Invalid Token. Please log in again.');
          navigate('/');
        }
      }

      console.error('API Error:', error);
      return Promise.reject(error);
    }
  );
};

export { api, setupInterceptors };

