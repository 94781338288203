//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : NewAdsModal.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (05 October 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241005     Jecyline     create NewAdsModal.jsx for add new chat room for ads clone
//
//***************************************************************************/
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const NewAdsModal = ({ showNewAdsModal, handleCloseNewAdsModal, handleCreateAdsCampaign, campaignType }) => {
  const { t } = useTranslation();

  const [newChatName, setNewChatName] = useState('');
  const [error, setError] = useState('');

  const handleCreateExistingBrand = () => {
    setError('');

    if(newChatName === ''){
      setError('Please key in campaign name');
    } else {
      handleCreateAdsCampaign(newChatName, 'Same');
      setNewChatName('');
    }
  };

  const handleCreateNewBrand = () => {
    setError('');

    if(newChatName === ''){
      setError('Please key in campaign name');
    } else {
      handleCreateAdsCampaign(newChatName, 'Other');
      setNewChatName('');
    }
  };

  return (
    <div>
      {/* Backdrop */}
      <div className={`modal-backdrop ${showNewAdsModal ? 'show' : ''}`} onClick={handleCloseNewAdsModal}></div>

      {/* Modal */}
      <div className={`modal custom-modal ${showNewAdsModal ? 'show d-block' : 'd-none'} fade`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{campaignType === 'Normal' ? t('New Campaign') : t(campaignType)}</h5>
              <button type="button" className="btn-close" onClick={handleCloseNewAdsModal}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                type="text"
                value={newChatName}
                onChange={(e) => setNewChatName(e.target.value)}
                placeholder={t('Enter campaign name')}
              />
              <span className="text-danger d-block">
                {error}
              </span>
            </div>
            <div className="submit-section mt-0 mb-3">
              <button type="button" className="btn btn-primary mx-1 mb-2" onClick={handleCreateExistingBrand}>
                {t('Create for my brand')}
              </button>
              <button type="button" className="btn btn-primary mx-1 mb-2" onClick={handleCreateNewBrand}>
                {t('Create for other brand')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAdsModal;
