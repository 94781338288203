//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : CompanyInfo.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (12 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240712     Jecyline     create CompanyInfo.jsx
//* 20240715     Jecyline     postal code to be numeric, company email change to email, email is readonly
//* 20240716     Jecyline     fix wrong placeholder for company services/products, add validation check for country code, fix serverError bug
//* 20240717     Jecyline     get REACT_APP_CAPTCHA_SITE_KEY from env
//* 20240718     Jecyline     language selection module
//* 20240822     Jecyline     use tarun api to get country list
//* 20240917     Glynnis      added website scrapper fields
//* 20240918     Glynnis      amended website scrapper fields
//* 20240920     Chris        remove company name fields , adjusted some fields , amended web scrapper fields
//* 20240925     Jecyline     add upload logo, display color scheme, pass new fields to backend
//* 20240929     Glynnis      added brand color, color picker
//* 20241002     Jecyline     remove company_service_or_product
//
//***************************************************************************/
import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import axios from "axios";
import { api } from '../../../services/api';
import Breadcrumbs from "../../../components/Breadcrumbs";
import { facebookicon } from "../../../Routes/ImagePath";
import Select from 'react-select';
// import countryList from 'react-select-country-list';
import ReCAPTCHA from 'react-google-recaptcha'
import useAxiosInterceptors from '../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { useTranslation } from 'react-i18next';
import API_ENDPOINTS from '../../../api/endpoints';
import { getFullImageUrl } from '../../../utils/urlUtils';
import InputColor from 'react-input-color';
import { useSelector, useDispatch } from 'react-redux';
import DotLoader from '../../../components/Loader/DotLoader'; 
import { isValidUrl } from '../../../utils/urlUtils';

const CompanyInfo = () => {

  const [color, setColor] = useState({});
  const [isLoading, setIsLoading ] = useState(false);
  const [isBrandScrapLoading, setIsBrandScrapLoading ] = useState(false);
  const [isSubmitting, setIsSubmitting ] = useState(false);
  const [scrapError, setScrapError ] = useState(false);

  // Call the custom hook to set up interceptors
  useAxiosInterceptors(); 
  const { t } = useTranslation();

  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({
    company_website: Yup.string().required(`${t('company website')}${t(' is required')}`).url(`${t('errors.INVALID_URL_FORMAT')}`),
    company_name: Yup.string().required(`${t('Brand name')}${t(' is required')}`),
    company_short_desc: Yup.string().required(`${t('Brand description')}${t(' is required')}`),
    brand_business_type: Yup.string().required(`${t('Brand business type')}${t(' is required')}`),
    logo_url: Yup.string().nullable(),
    logo_file: Yup.mixed().nullable(),
    // Add a custom validation rule
    logo_validation: Yup.string().test('logo-required', 
      (value, context) => {
        const { logo_url, logo_file } = context.parent; // Access other fields
        if (!logo_url && !logo_file) {
          return new Yup.ValidationError(
            `${t('Brand Logo')} ${t('is required')}`,
            null,
            'logo_validation' // Use this field name for error
          );
        }
        return true;
      }
    ),    // brand_colors: Yup.array().required(`${t('At least 2 brand color')}${t(' is required')}`),
    company_address: Yup.string().required(`${t('company address')}${t(' is required')}`),
    company_postal_code: Yup.string()
      .matches(/^\d+$/, `${t('company postal code')}${t(' must be numeric')}`)
      .required(`${t('company postal code')}${t(' is required')}`),
    company_state: Yup.string().required(`${t('company state')}${t(' is required')}`),
    company_country_code: Yup.string().required(`${t('Country')}${t(' is required')}`),
    company_phone_no: Yup.string().required(`${t('company phone no')}${t(' is required')}`),
  });

  // Initialize the form with react-hook-form and Yup resolver
  const { 
    control, 
    handleSubmit, 
    setValue, 
    formState: { errors } 
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: '',
      status: '',
      company_website:'',
      company_name: '',
      company_short_desc: '',
      brand_business_type: '',
      logo_url: '',
      logo_file: '',
      brand_colors: [],
      email: '',
      company_address: '',
      company_postal_code: '',
      company_state: '',
      company_country_code: '',
      company_phone_no: '',
    }
  });

  const navigate = useNavigate();
  const recaptcha = useRef()

  const [serverErrors, setServerErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);

  // const testdownloadimg = async () => {
  //       axios.get("http://omninexus.io/assets/img/logo-light.png",
  //       {
  //           responseType: 'arraybuffer',
  //           headers: {
  //               'Content-Type': 'application/json',
  //               'Accept': 'application/png'
  //           }
  //       })
  //       .then((response) => {

  //           console.log(response);
  //           const url = window.URL.createObjectURL(new Blob([response.data]));
  //           const link = document.createElement('a');
  //           link.href = url;
  //           link.setAttribute('download', 'file.pdf'); //or any other extension
  //           document.body.appendChild(link);
  //           link.click();
  //       })
  //       .catch((error) => console.log(error));
  //     };

  //     useEffect(() => {
  //       testdownloadimg();
  //     }, []);

  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MARVIS_AI}/countries`);
        // console.log('response',response);

        const countryList = response.data.all_countries;
        
        const countries = countryList.map(country => ({
          value: country.cca2,
          label: country.name.common,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
        
        setCountryOptions(countries);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCountryList();

  }, []);

  useEffect(() => {
    setIsLoading(true);

    fetchCompanyInfo();
  }, []);

  const fetchCompanyInfo = async () => {
    try {
      const response = await api.get(`/company`);
      const data = response.data;

      setValue('id', data.id);
      setValue('status', data.status);
      setValue('email', data.email || '');
      setValue('company_address', data.company_address || '');
      setValue('company_postal_code', data.company_postal_code || '');
      setValue('company_state', data.company_state || '');
      setValue('company_country_code', data.company_country_code || '');
      setValue('company_phone_no', data.company_phone_no || '');

      setValue('company_website', data.company_website || '');
      setBrandUrl(data.company_website || '');

      setValue('company_name', data.company_name || '');
      setValue('company_short_desc', data.company_short_desc || '');
      setValue('brand_business_type', data.brand_business_type || '');
      setValue('logo_url', data.logo_url || '');

      setImage(getFullImageUrl(data.logo_url));
      setOriLogoUrl(data.logo_url);

      setValue('brand_colors', data.brand_colors || '');
      if(data.brand_colors) {
        // const brandColorsArray = JSON.parse(data.brand_colors);
        const brandColorsArray = data.brand_colors.split(',');

        setBrandColor1(brandColorsArray[0]);
        setBrandColor2(brandColorsArray[1]);
        setBrandColor3(brandColorsArray[2]);
        setBrandColor4(brandColorsArray[3]);
        setBrandColor5(brandColorsArray[4]);
        setBrandColor6(brandColorsArray[5]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching company info:', error);
    }
  };

   /* Call Web Scrapper API */

   const [oriLogoUrl, setOriLogoUrl] = useState('');  // State to store image URL from API
   const [image, setImage] = useState(null);  // State to store image URL from API
   const [brandUrl, setBrandUrl] = useState('');
   const [brand1, setBrandColor1] = useState('');
   const [brand2, setBrandColor2] = useState('');
   const [brand3, setBrandColor3] = useState('');
   const [brand4, setBrandColor4] = useState('');
   const [brand5, setBrandColor5] = useState('');
   const [brand6, setBrandColor6] = useState('');

   const fileInputRef = useRef(null); // Ref to access the file input

  const aiWebScrapperApi = async () => {
    setScrapError(false);

    setIsBrandScrapLoading(true);
    console.log('aiWebScrapperApi: brandUrl', brandUrl);

     if(brandUrl === '')
     {
       alert('Kindly input a website URL.');
       setIsBrandScrapLoading(false);
     }
     else
     {
       setValue('company_website', brandUrl || '');

       try {
        const data = await api.get(API_ENDPOINTS.MARVIS_WEB_SCRAPPER(brandUrl)).then(response => {setBrandValue(response.data)});

       } catch (error) {
        console.error('aiWebScrapperApi Error:', error);
        setIsBrandScrapLoading(false);
        setScrapError(true)
       }

     }
   };
 
  const setBrandValue = (data) => {
    console.log('scrap data', data);
    resetBrandValue();

    setValue('company_name', data.brand_name || '');
    setValue('company_short_desc', data.brand_description || '');
    setValue('brand_business_type', data.business_type || '');

    //handle image url
    if (data.logo_images && data.logo_images.length > 0) {
      const logos = data.logo_images; // Get logo images array

      // Loop through each logo image URL
      for (const logoImageUrl of logos) {
        if (isValidUrl(logoImageUrl)) {
          setValue('logo_url', logoImageUrl || '');
          handleImageChange('logo_url', logoImageUrl || '');
          setImage(logoImageUrl);
          break; // Exit the loop after storing the first valid URL
        }
      }
    }

    setBrandColor1(data.brand_colors[0]);
    setBrandColor2(data.brand_colors[1]);
    setBrandColor3(data.brand_colors[2]);
    setBrandColor4(data.brand_colors[3]);
    setBrandColor5(data.brand_colors[4]);
    setBrandColor6(data.brand_colors[5]);

    setValue('brand_colors', data.brand_colors.slice(0, 6));
    setIsBrandScrapLoading(false);
  };

  const resetBrandValue = () => {
    console.log('resetBrandValue');

    setValue('company_name', '');
    setValue('company_short_desc', '');
    setValue('brand_business_type', '');

    setValue('logo_url', '');
    handleImageChange('logo_url', '');
    setImage(null);

    setBrandColor1();
    setBrandColor2();
    setBrandColor3();
    setBrandColor4();
    setBrandColor5();
    setBrandColor6();

    setValue('brand_colors', []);
  };

 
   /* End Call Web Scrapper API */

  // const [colorScheme, setColorScheme] = useState('');

  // const handleChange = (e) => {
  //   setColorScheme(e.target.value);
  // };

  const handleImageChange = (type, item) => {

    if(type === 'logo_url'){
      if(item && item !== '') {
        setImage(item);
        setValue('logo_file', '');

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    } else {
      if (item && item.length > 0) {  
        const fileObj = item[0];  
        const previewUrl = URL.createObjectURL(fileObj);
        setImage(previewUrl);
        setValue('logo_url', '');
      } else {
        setImage(getFullImageUrl(oriLogoUrl));
        setValue('logo_url', oriLogoUrl);

      }
    }
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    // alert(`Selected Color Scheme: ${colorScheme}`);
    try { // verify captcha
      const captchaToken = recaptcha.current.getValue()
      if (!captchaToken) {
        setIsSubmitting(false);
        setCaptchaError(`${t('Please verify the reCAPTCHA')}`);
        setTimeout(() => setCaptchaError(''), 5000);
      } else {
        await api.post(`/verify-captcha`, {captchaToken});
        
        const formData = new FormData();
        // formData.append('id', data.id);
        // formData.append('status', data.status);
        formData.append('company_address', data.company_address);
        formData.append('company_postal_code', data.company_postal_code);
        formData.append('company_state', data.company_state);
        formData.append('company_country_code', data.company_country_code);
        formData.append('company_phone_no', data.company_phone_no);

        formData.append('company_website', data.company_website);
        formData.append('company_name', data.company_name);
        formData.append('company_short_desc', data.company_short_desc);
        formData.append('brand_business_type', data.brand_business_type);
        formData.append('logo_url', data.logo_url);
        formData.append('logo_file', data.logo_file);
        formData.append('brand_colors', data.brand_colors);

        try { //update company info
          await api.post(`/company`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          recaptcha.current.reset();
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setSuccessMessage(`${t('Company info')} ${t(' updated successfully')}`); // Call the success message handler
          setTimeout(() => setSuccessMessage(''), 5000);
          setIsSubmitting(false);

        } catch (error) {
          setIsSubmitting(false);

          recaptcha.current.reset();
          if (error.response && error.response.data) {
            setServerErrors(error.response.data);
          } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setErrorMessage(`${t('An error occurred while updating company info')}`);
            setTimeout(() => setErrorMessage(''), 5000); 
          }
        }
      }
    } catch (error) {  
      setIsSubmitting(false);

      console.log(error);    
      setCaptchaError(error.response.data.error);
      setTimeout(() => setCaptchaError(''), 5000);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid company-info">
          <Breadcrumbs
            maintitle={t('Company Info')}
            title={t('Dashboard')}
            subtitle={t('Company Info')}
          />

          {/* Company Info Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="row justify-content-center">
              {/* Website */}
              <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 col-md-9">
                          <div className="input-block mb-3">
                            <Controller
                              name="company_website"
                              control={control}
                              render={({ field }) => (
                                <input
                                  // className={`form-control`}
                                  className={`form-control ${isLoading ? 'loading' : ''}`}
                                  type="text"
                                  placeholder={`${t('Enter your')} ${t('company website')}`}
                                  {...field}
                                  autoComplete="false"
                                  onChange={(e) => {
                                    field.onChange(e); // This updates the react-hook-form state
                                    setBrandUrl(e.target.value); // This sets your additional state
                                  }}
                                />
                              )}
                            />
                            <span className="text-danger d-block">
                              {scrapError && (
                                t('Oops! It looks like there’s a problem with the URL. Please try a different one.')
                              )}
                              {errors.company_website?.message}
                              {serverErrors.company_website && <p>{serverErrors.company_website[0]}</p>}
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-md-3 ">
                          <div className="input-block mb-3 d-flex align-items-center justify-content-center">
                            <button className="btn btn-primary company-btn" type="button" onClick={aiWebScrapperApi} disabled={isBrandScrapLoading}>
                              {/* {t('Import')} */}
                              {isBrandScrapLoading ? t('Importing...') : t('Import')}
                            </button>
                          {/* { isBrandScrapLoading ?  ( 
                            <DotLoader />
                          ):  (
                            <button className="btn btn-primary company-btn" type="button" onClick={aiWebScrapperApi} disabled={isBrandScrapLoading}>{t('Import')}</button>
                          )} */}
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                {/* Brand Information */}
                <div className="row">
                  <div className="col-sm-12 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">
                          {t('Brand Information')}{" "}
                        </h3>
                        <div className="input-block mb-3">
                          <label className="col-form-label">{t('Brand Name')}</label>
                          <Controller
                            name="company_name"
                            control={control}
                            render={({ field }) => (
                              <input
                                // className={`form-control`}
                                className={`form-control ${(isBrandScrapLoading || isLoading) ? 'loading' : ''}`}
                                type="text"
                                placeholder={`${t('Enter')} ${t('brand name')}`}
                                {...field}
                                autoComplete="false"
                                disabled={isBrandScrapLoading}
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.company_name?.message}
                            {serverErrors.company_name && <p>{serverErrors.company_name[0]}</p>}
                          </span>
                        </div>
                        
                        <div className="input-block mb-3">
                          <label className="col-form-label">{t('Brand Description')}</label>
                          <Controller
                            name="company_short_desc"
                            control={control}
                            render={({ field }) => (
                              <textarea
                                // className={`form-control`}
                                className={`form-control ${(isBrandScrapLoading || isLoading) ? 'loading' : ''}`}
                                placeholder={`${t('Enter')} ${t('brand description')}`}
                                {...field}
                                rows={2}
                                autoComplete="off"
                                disabled={isBrandScrapLoading}
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.company_short_desc?.message}
                            {serverErrors.company_short_desc && <p>{serverErrors.company_short_desc[0]}</p>}
                          </span>
                        </div>

                        <div className="input-block mb-3">
                          <label className="col-form-label">{t('Business Type')}</label>
                          <Controller
                            name="brand_business_type"
                            control={control}
                            render={({ field }) => (
                              <input
                                // className={`form-control`}
                                className={`form-control ${(isBrandScrapLoading || isLoading) ? 'loading' : ''}`}
                                type="text"
                                placeholder={`${t('Enter')} ${t('business type')}`}
                                {...field}
                                autoComplete="false"
                                disabled={isBrandScrapLoading}
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.brand_business_type?.message}
                            {serverErrors.brand_business_type && <p>{serverErrors.brand_business_type[0]}</p>}
                          </span>
                        </div>

                        <div className="input-block mb-3">
                          <label className="col-form-label">{t('Brand Logo')}</label>
                          <Controller
                            name="logo_url"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control`}
                                type="hidden"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e.target.value); // Update the logo_url state
                                  handleImageChange('logo_url', e.target.value); // Pass the URL to handleImageChange
                                }}                                 />
                            )}
                          />
                          <Controller
                            name="logo_file"
                            control={control}
                            ref={fileInputRef}
                            render={({ field: { onChange } }) => (
                              <input
                                // className={`form-control`}
                                className={`form-control ${(isBrandScrapLoading || isLoading) ? 'loading' : ''}`}
                                type="file"
                                placeholder={`${t('Enter your')} ${t('brand logo')}`}
                                accept="image/*"
                                disabled={isBrandScrapLoading}
                                onChange={(e) => {
                                  onChange(e.target.files[0]); // Update the form state with the selected file
                                  handleImageChange('logo_file', e.target.files); // Pass the file to handleImageChange
                                }}
                              />
                            )}         
                          />
                          
                          {image && (
                            <div className="col-12 col-md-12 d-flex align-items-center justify-content-center">
                              <img src={image} alt="Brand Logo" style={{ margin:'5px', width: '150px', height: '150px', objectFit: 'contain' }} />
                            </div>
                          )}

                          <span className="text-danger d-block">
                            {errors.logo_validation?.message}

                            {serverErrors.logo_url && <p>{serverErrors.logo_url[0]}</p>}
                            {serverErrors.logo_file && <p>{serverErrors.logo_file[0]}</p>}
                          </span>
                        </div>

                        <div className="input-block mb-3">
                          <label className="col-form-label">{t('Brand Color Scheme')}</label>
                            <div className="col-12 col-md-12 d-flex align-items-center justify-content-center">
                              {brand1 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand1}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand1,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand2 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand2}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand2,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand3 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand3}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand3,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand4 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand4}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand4,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand5 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand5}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand5,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand6 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand6}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand6,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                          </div>

                          <Controller
                            name="brand_colors"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control`}
                                type="hidden"
                                {...field}
                              />
                            )}
                          />

                          <span className="text-danger d-block">
                            {errors.brand_colors?.message}
                            {serverErrors.brand_colors && <p>{serverErrors.brand_colors[0]}</p>}
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* Brand Details end */}
              </div>
              
              {/* Company Basic Information */}
              <div className="col-12 col-md-6 d-flex">
                <div className="card profile-box flex-fill">
                  <div className="card-body">
                    <h3 className="card-title">
                      {t('Company Information')}{" "}
                    </h3>

                     {/* phone no group start */}
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Phone No')}</label>
                      <Controller
                        name="company_phone_no"
                        control={control}
                        render={({ field }) => (
                          <input
                            // className={`form-control`}
                            className={`form-control ${isLoading ? 'loading' : ''}`}
                            type="text"
                            placeholder={`${t('Enter')} ${t('company phone no')}`}
                            {...field}
                            autoComplete="false"
                            disabled={isBrandScrapLoading}
                          />
                        )}
                      />
                      <span className="text-danger d-block">
                        {errors.company_phone_no?.message}
                        {serverErrors.company_phone_no && <p>{serverErrors.company_phone_no[0]}</p>}
                      </span>
                    </div>
                     {/* phone no group end */}

                    {/* Email group start */}
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Email')}</label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            // className={`form-control`}
                            className={`form-control ${isLoading ? 'loading' : ''}`}
                            type="text"
                            {...field}
                            autoComplete="false"
                            disabled
                          />
                        )}
                      />
                      <span className="text-danger d-block">
                        {errors.email?.message}
                        {serverErrors.email && <p>{serverErrors.email[0]}</p>}
                      </span>
                    </div>
                    {/* Email group end */}

                    {/* Address Group */}
                    <div className="input-block mb-3">
                      <label className="col-form-label">{t('Address')}</label>
                      <Controller
                        name="company_address"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            // className={`form-control`}
                            className={`form-control ${isLoading ? 'loading' : ''}`}
                            placeholder={`${t('Enter')} ${t('company address')}`}
                            {...field}
                            rows={2}
                            disabled={isBrandScrapLoading}
                            autoComplete="off"
                          />
                        )}
                      />
                      <span className="text-danger d-block">
                        {errors.company_address?.message}
                        {serverErrors.company_address && <p>{serverErrors.company_address[0]}</p>}
                      </span>
                    </div>

                    <div className="input-block mb-3">
                      <Controller
                        name="company_postal_code"
                        control={control}
                        render={({ field }) => (
                          <input
                            // className={`form-control`}
                            className={`form-control ${isLoading ? 'loading' : ''}`}
                            type="text"
                            placeholder={`${t('Enter')} ${t('company postal code')}`}
                            {...field}
                            autoComplete="false"
                            disabled={isBrandScrapLoading}
                          />
                        )}
                      />
                      <span className="text-danger d-block">
                        {errors.company_postal_code?.message}
                        {serverErrors.company_postal_code && <p>{serverErrors.company_postal_code[0]}</p>}
                      </span>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="input-block mb-3">
                          <Controller
                            name="company_state"
                            control={control}
                            render={({ field }) => (
                              <input
                                // className={`form-control`}
                                className={`form-control ${isLoading ? 'loading' : ''}`}
                                type="text"
                                placeholder={`${t('Enter')} ${t('company state')}`}
                                {...field}
                                autoComplete="false"
                                disabled={isBrandScrapLoading}
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.company_state?.message}
                            {serverErrors.company_state && <p>{serverErrors.company_state[0]}</p>}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="input-block mb-3">
                          <Controller
                            name="company_country_code"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={countryOptions}
                                placeholder={`${t('Select')} ${t('company country')}`}
                                classNamePrefix="react-select"
                                className={isLoading ? 'loading-select' : ''}
                                onChange={(selectedOption) => {
                                  // Pass only the selected value (country code) to the backend
                                  field.onChange(selectedOption ? selectedOption.value : null);
                                }}
                                value={countryOptions.find(option => option.value === field.value)}
                                isClearable  // Allows clearing the selection
                                disabled={isBrandScrapLoading}
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.company_country_code?.message}
                            {serverErrors.company_country_code && <p>{serverErrors.company_country_code[0]}</p>}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Address Group end */}

                  </div>
                </div>
              </div>
              {/* Company Basic Information end */}
            </div>

            {/* Connection Facebook Accounts */}
            <div className="row">
              {/*<div className="col-sm-6 d-flex">
                <div className="card profile-box flex-fill">
                  <div className="card-body">
                    <h3 className="card-title">
                      {t('Connection Facebook Accounts')}{" "}
                    </h3>
                    <div className='input-block mb-3 d-flex align-items-center justify-content-center'>
                      <button type="button" className="account-icon-button">
                        <img src={facebookicon} alt="Facebook" className="account-img-icon"/>
                        {t('Facebook')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>*/}
              {/* Connection Facebook Accounts end */}

              {/* Recaptcha & Submit*/}
              <div className="col-md-12 d-flex">
                <div className="card profile-box flex-fill">
                  <div className="card-body">
                    {/* CAPTCHA */}
                    <div className="input-block mb-3">
                      <div className="col-12 col-md-12 d-grid align-items-center justify-content-center btn-group-vertical ">
                        <div className="col-8 col-md-8 col-sm-8 d-flex align-items-center mb-3">
                          <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} />
                          
                          {captchaError && (
                            <span className="text-danger d-block">
                              {captchaError}
                            </span>
                          )}
                        </div> 
                        <div className="input-block mb-3 d-flex align-items-center justify-content-center">
                          <button type="submit" className="btn btn-primary company-btn" disabled={isBrandScrapLoading||isSubmitting}>
                            {isSubmitting ? t('Submitting...') : t('Submit')}
                          </button>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Recaptcha & Submit end*/}
            </div>
          </form>
          {/* Company Info Form end */}
        </div>
        {/* /Page Content end */}
      </div>
    </>
  );
};

export default CompanyInfo;
