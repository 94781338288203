//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : fileManagerSlice.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (30 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240730     Jecyline      create fileManagerSlice.jsx for file manager reducer
//* 20240802     Jecyline      add appendFileCurrentChatDetails, setFileCurrentPage, setFileHasMore, setFileIsLoadingChatDetails for file manager pagination
//
//***************************************************************************/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fileChatList: [],
  currentFileChatData: null,
  currentFileTodayChatDetails: [],
  currentFileOlderChatDetails: [],
  fileCurrentPage: 1,
  fileHasMore: true,
  fileIsLoadingChatDetails: false,
};

export const fileManagerSlice = createSlice({
  name: "file_manager",
  initialState,
  reducers: {
    setFileChatList: (state, action) => {
      const data = { id: 'all', name: 'All Campaigns' };
      state.fileChatList = [data, ...action.payload];
    },
    setFileCurrentChatData: (state, action) => {
      state.currentFileChatData = action.payload;
    },
    setFileCurrentChatDetails: (state, action) => {
      state.currentFileTodayChatDetails = action.payload.today;
      state.currentFileOlderChatDetails = action.payload.older;
    },
    appendFileCurrentChatDetails: (state, action) => {
      const { chat_id, today, older } = action.payload;

      if (chat_id !== state.currentFileChatData.id) {
        return;
      }
      
      if(Array.isArray(today)) {
        state.currentFileTodayChatDetails = [
          ...(state.currentFileTodayChatDetails || []),
          ...today
        ];
      }

      if(Array.isArray(older)) {
        state.currentFileOlderChatDetails = [
          ...(state.currentFileOlderChatDetails || []),
          ...older
        ];
      }
    },
    setFileCurrentPage: (state, action) => {
      state.fileCurrentPage = action.payload;
    },
    setFileHasMore: (state, action) => {
      state.fileHasMore = action.payload;
    },
    setFileIsLoadingChatDetails: (state, action) => { //loading for load chat file
      state.fileIsLoadingChatDetails = action.payload;
    },
  },
});

export const { 
  setFileChatList, 
  setFileCurrentChatData,
  setFileCurrentChatDetails,
  setFileCurrentPage,
  appendFileCurrentChatDetails,
  setFileHasMore,
  setFileIsLoadingChatDetails,
} = fileManagerSlice.actions;

export default fileManagerSlice.reducer;
