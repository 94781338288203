//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : index.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline     Add index.s file for Campaign Summary
//
//***************************************************************************/
import React, { Suspense, useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { 
  getCampaignListAction, 
} from '../../../../store/actions/campaignSummaryActions';

const CampaignSummaryChatSidebar = React.lazy(() => import('./campaignSummarySidebar'));
const CampaignSummaryView = React.lazy(() => import('./campaignSummaryView'));

const CampaignSummary = () => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    // Call the API with the search query
    document.body.classList.remove("slide-nav");
    toggleSidebar();
    const fetchResults = async () => {
      /////////////////////////////////////
      // campaignSummaryAction: getCampaignListAction
      dispatch(getCampaignListAction({ 
        name: searchName,
      }));
    };

    fetchResults();
  }, [searchName, dispatch]);

  const handleInputChange = (event) => {
    setSearchName(event.target.value);
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className={`file-wrap ${isSidebarOpen ? '' : 'file-sidebar-toggle'}`}>
                <div className="file-sidebar">
                {/* <div className="file-sidebar"> */}
                  <div className="file-header justify-content-center">
                    <span>{t('Campaigns')}</span>
                    <Link 
                      to="#" 
                      className="file-side-close"
                      onClick={toggleSidebar}
                    >
                      <i className="fa-solid fa-xmark" />
                    </Link>
                  </div>
                  <form className="file-search">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="fa-solid fa-magnifying-glass" />
                      </div>
                      <input
                        type="text"
                        className="form-control rounded-pill"
                        placeholder={`${t('Search')}`}
                        value={searchName}
                        onChange={handleInputChange}
                        autoComplete='off'
                      />
                    </div>
                  </form>
                  <Suspense fallback={<div>{t('Loading...')}</div>}>
                    <CampaignSummaryChatSidebar clickCampaignSummaryActions={toggleSidebar}/>
                  </Suspense>

                </div>
                <div className="file-cont-wrap">
                  <div className="file-cont-inner">
                    <div className="file-cont-header">
                      <div className="file-options">
                        <Link
                          to="#"
                          id="file_sidebar_toggle"
                          className="file-sidebar-toggle"
                          onClick={toggleSidebar}
                        >
                          <i className="fa-solid fa-bars" />
                        </Link>
                      </div>
                      <span>{t('Campaign Summary')}</span>
                      <div className="file-options">
                        <span className="btn-file">
                          {/* <input type="file" className="upload" />
                          <i className="fa-solid fa-upload" /> */}
                        </span>
                      </div>
                    </div>
                    <div className="file-content">
                      <Suspense fallback={<div>{t('Loading...')}</div>}>
                        <CampaignSummaryView />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};

export default CampaignSummary;
