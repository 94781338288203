//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : chatSelectors.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240729     Jecyline      create chatSelectors.jsx for chat reducer
//* 20240813     Jecyline      add selectSelection, selectChatSelectionForCurrentChat
//* 20240829     Jecyline      add more selector
//* 20240905     Jecyline      add selectHasMore, selectIsLoadingChatDetails, selectIsLoadingFirstPage, selectChatStage
//* 20240911     Jecyline      fix bug for selectChatSelectionForCurrentChat
//* 20240930     Jecyline      add selectTempPrompt, selectTempPromptForCurrentChat
//* 20241002     Jecyline      add selectLoadingMoreAds, selectChatSelection, selectChatSelectionById, selectLoadingMoreAdsForCurrentChat
//* 20241004     Jecyline      add selectChatStageForCurrentChat, selectChatOptionById
//
//***************************************************************************/
import { createSelector } from 'reselect';

export const selectCurrentChatData = (state) => state.chat.currentChatData;
export const selectChatSelection = (state) => state.chat.chatSelection;
export const selectBrandBoostDetail = (state) => state.chat.brandBoostDetail;
export const selectChatLoader = (state) => state.chat.chatLoader;
export const selectLoadingMoreAds = (state) => state.chat.loadingMoreAds;
export const selectTempPrompt = (state) => state.chat.tempPrompt;
export const selectChatMemory = (state) => state.chat.chatMemory;
export const selectCurrentPage = (state) => state.chat.currentPage;
export const selectHasMore = (state) => state.chat.hasMore;
export const selectIsLoadingChatDetails = (state) => state.chat.isLoadingChatDetails;
export const selectIsLoadingFirstPage = (state) => state.chat.isLoadingFirstPage;
export const selectChatStage = (state) => state.chat.chatStage;
export const selectChatOption = (state) => state.chat.chatOption;

//currentChat: chatStage
export const selectChatStageForCurrentChat = createSelector(
  [selectCurrentChatData, selectChatStage],
  (currentChatData, chatStage) => {
    if (!currentChatData || !currentChatData.id) return { 
      campaign_type: 'Normal',
      stage: '1',
      subStage: 'initial_prompt', 
      subStageOption: '',
      initial_prompt: '',
      initial_reply: '',
      chat_detail_id: '',
      selected_copy: '',
      selected_image_url: '',
      selected_image_desc: '',
      product_keyword: '',
      ads_location: '',
      image_size: '',
      image_style: '',
      source_url: '',
      source_desc: '',
      mask_url: '',
      mask_desc: '',
      status: 'pending',
      adsclone_copy_id: '',
      adsclone_image_id: '',
      adsclone_copy: '',
      adsclone_image_url: '',
      adsclone_image_desc: '',
    };
    return chatStage[currentChatData.id] || { temp_prompt: '', prompt_time: '' };
  }
);

//brandBoostDetail of specific chat id
export const selectBrandBoostDetailById = createSelector(
  [selectBrandBoostDetail, (state, chat_id) => chat_id],
  (brandBoostDetail, chat_id) => {
    return brandBoostDetail[chat_id] || {
      keyword_approach: '',
      keyword: '',
      keyword_url: '',
      facebook_keyword: '',
      flexible_spec_interest: {},
      reach: '',
      target_audience: {},
      target_spec: {},
      budget: '',
      // audience_size_lower_bound:'',
      // audience_size_upper_bound:'',
      summary: '',
    };
  }
);

//currentChat: tempPrompt
export const selectTempPromptForCurrentChat = createSelector(
  [selectCurrentChatData, selectTempPrompt],
  (currentChatData, tempPrompt) => {
    if (!currentChatData || !currentChatData.id) return { temp_prompt: '', prompt_time: '' };
    return tempPrompt[currentChatData.id] || { temp_prompt: '', prompt_time: '' };
  }
);

//currentChat: chatLoader
export const selectChatLoaderForCurrentChat = createSelector(
  [selectCurrentChatData, selectChatLoader],
  (currentChatData, chatLoader) => {
    if (!currentChatData || !currentChatData.id) return { isLoading: false, message: '' };
    return chatLoader[currentChatData.id] || { isLoading: false, message: '' };
  }
);

//chatOption of specific chat id
export const selectChatOptionById = createSelector(
  [selectChatOption, (state, chat_id) => chat_id],
  (chatOption, chat_id) => {
    return chatOption[chat_id] || [];
  }
);

//currentChat: chatSelection
export const selectChatSelectionForCurrentChat = createSelector(
  [selectCurrentChatData, selectChatSelection],
  (currentChatData, chatSelection) => {
    if (!currentChatData || !currentChatData.id) return { isDisplay: false, animationClass: 'hidden' };
    return chatSelection[currentChatData.id] || { isDisplay: false, animationClass: 'hidden' };
  }
);

//chatSelection of specific chat id
export const selectChatSelectionById = createSelector(
  [selectChatSelection, (state, chat_id) => chat_id],
  (chatSelection, chat_id) => {
    return chatSelection[chat_id] || {
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: false, 
      target: '',
      title: '', 
      options: [],
      end_cursor: '',
      animationClass: 'hidden',
    };
  }
);

//currentChat: loadingMoreAds
export const selectLoadingMoreAdsForCurrentChat = createSelector(
  [selectCurrentChatData, selectLoadingMoreAds],
  (currentChatData, loadingMoreAds) => {
    if (!currentChatData || !currentChatData.id) return false;
    return loadingMoreAds[currentChatData.id] || false;
  }
);

//currentChat: chatMemory
export const selectChatMemoryForCurrentChat = createSelector(
  [selectCurrentChatData, selectChatMemory],
  (currentChatData, chatMemory) => {
    if (!currentChatData || !currentChatData.id) return [];
    return chatMemory[currentChatData.id] || [];
  }
);
