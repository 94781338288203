//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : ForgotPassword.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign   Description
//* ------------ ----   ------------------------------------------------------
//* 20240701     Glynnis Function features short description
//* 20241002     Asheraf Reset Password
//
//***************************************************************************/
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { CoverPhoto } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import * as Yup from "yup";
import LanguageSelector from '../../layout/LanguageSelector';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { api_url } from "../../../base_urls";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const { t } = useTranslation();

  const {
      control,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
  });

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      const response = await axios.post(api_url + '/verify-email', { email });
      const { access_token, token_type, expires_in, user } = response.data;
      const redirect = 'reset-password';
      navigate('/otp', { state: { email: email , redirect: redirect } });
      try {
        const response = await fetch(api_url + '/resend-otp', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email , redirect})
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to resend otp');
        } else {
          const responseData = await response.json();
          // console.log(responseData);

          setMessage(responseData.message);
          setTimeout(() => setMessage(''), 10000); // Clear message after 5 seconds
        }

      } catch (error) {
        console.error('Error:', error.message);
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        setMessage(error.response.data.error);
        setTimeout(() => setMessage(''), 10000);

      } else if (error.request) {
        setMessage('Network error - please try again later');
        setTimeout(() => setMessage(''), 10000);

      } else {
        console.error('Error:', error.message);

        setMessage('Something went wrong - please try again later');
        setTimeout(() => setMessage(''), 10000);

      }
    }
  };

  return (
    <div className="account-page">
      {/* /forget Section */}
      <div className="forget-wrapper">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <Link to="/app/main/dashboard">
                  <img src={Applogo} alt="Dreamguy's Technologies" />
                </Link>
              </div>
              <div className="account-box">
                <div className="account-wrapper">
                  <LanguageSelector />

                  <p className="account-subtitle">
                    <label className="col-form-label">{t('Enter your email to get a password reset link')}</label>
                  </p>
                  
                  {message && (
                    <div className={`alert ${message.includes('success') ? 'alert-success' : 'alert-danger'}`}>
                      {t(`errors.${message}`)}
                    </div>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div className="input-block">
                      <Controller
                        type="text"
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control ${
                              error?.email ? "error-input" : ""
                            }`}
                            type="text"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="true"
                            required
                          />
                        )}
                      />
                    </div>
                    <div className="input-block text-center">
                      <button
                        className="btn btn-primary account-btn"
                        type="submit">
                        {t('Reset Password')}
                      </button>
                    </div>
                    <div className="account-footer">
                      <p>
                        {t('Remember your password?')} <Link to="/">{t('Login')}</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Cover Section */}
      <div className="cover-wrapper">
          <div className="mb-12 cover-div">
          <img src={CoverPhoto} alt="Omninexus"/>
          </div>
      </div>
      {/* End Cover Section */}
    </div>
  );
};
export default ForgotPassword;
