//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : base_url.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      create base_urls.jsx
//* 20240705     Jecyline     change api_url value
//* 20240712     Jecyline     add CAPTCHA_SITE_KEY
//* 20240715     Jecyline     add frontend_url
//* 20240717     Jecyline     remove CAPTCHA_SITE_KEY
//* 20240724     Jecyline     add backend_url
//* 20240801     Glynnis      add staging url
//* 20240802     Jecyline     change local host urls
//
//***************************************************************************/

// Local Host urls
// export const base_url = "/";
// // export const api_url = "https://backend.omninexus.io";
// export const frontend_url = "http://127.0.0.1:3000";
// export const backend_url = "http://127.0.0.1:8000";
// export const api_url = backend_url + "/api";
// export const canva_url = "http://127.0.0.1:3001/image-editor";

// Staging urls
export const base_url = "/";
// export const api_url = "https://marvis-2c87cabdb912.herokuapp.com/https://backend.omninexus.io";
export const frontend_url = "https://marvis-2c87cabdb912.herokuapp.com/https://staging.omninexus.io";
export const api_url = "https://marvis-2c87cabdb912.herokuapp.com/https://portal.staging.omninexus.io/api";
export const backend_url = "https://marvis-2c87cabdb912.herokuapp.com/https://portal.staging.omninexus.io";
export const canva_url = "https://marvis-2c87cabdb912.herokuapp.com/https://omninexus.io/editor";

// Production urls
// export const base_url = "/";
// // export const api_url = "https://marvis-2c87cabdb912.herokuapp.com/https://backend.omninexus.io";
// export const frontend_url = "https://marvis.omninexus.io";
// export const api_url = "https://marvis-2c87cabdb912.herokuapp.com/https://portal.omninexus.io/api";
// export const backend_url = "https://portal.omninexus.io";
// export const canva_url = "https://omninexus.io/editor";

