//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : ResetPassword.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign   Description
//* ------------ ----   ------------------------------------------------------
//* 20240701     Glynnis Function features short description
//* 20241002     Asheraf Reset Password
//
//***************************************************************************/
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { CoverPhoto } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { passwordrgx } from "../../../utils/RegEx";
import { api_url } from "../../../base_urls";
import LanguageSelector from '../../layout/LanguageSelector';


const ResetPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { email } = location.state;

  
  const validationSchema = Yup.object().shape({
    password: Yup
      .string()
      .matches(passwordrgx, {
        message: `${t('Password')} ${t(' must be 8-20 characters long, include at least one uppercase letter, one digit, and one special character')}`,
        excludeEmptyString: true
      })
      .required(`${t('Password')}${t(' is required')}`)
      .trim(),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password'), null], `${t('Password must match')}`)
      .required(`${t('Please confirm your password')}`),
  });

  const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
  });

  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [passwordEye, setPasswordEye] = useState(true);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(true);
  const [serverErrors, setServerErrors] = useState({});


  const onSubmit = async (data) => {

    console.log(email);
    try {
      const response = await fetch(api_url + '/reset-password', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('refresh_token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email ,password: data.password })
      });
      
      if (!response.ok) {
        setMessage('INVALID_REQUEST');
        setTimeout(() => setMessage(''), 10000);
      }else{
        navigate('/');
        localStorage.removeItem('refresh_token');
      }

    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        setMessage(error.response.data.error);
        setTimeout(() => setMessage(''), 10000);

      } else if (error.request) {
        setMessage('Network error - please try again later');
        setTimeout(() => setMessage(''), 10000);

      } else {
        console.error('Error:', error.message);

        setMessage('Something went wrong - please try again later');
        setTimeout(() => setMessage(''), 10000);

      }
    }
  };

  return (
    <div className="account-page">
      {/* /reset Section */}
      <div className="reset-wrapper">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <Link to="/app/main/dashboard">
                  <img src={Applogo} alt="Dreamguy's Technologies" />
                </Link>
              </div>
              <div className="account-box">
                <div className="account-wrapper">
                  <LanguageSelector />
                  <p className="account-subtitle">
                    <label className="col-form-label">{t('Enter your email to get a password reset link')}</label>
                  </p>
                  
                  {message && (
                    <div className={`alert ${message.includes('success') ? 'alert-success' : 'alert-danger'}`}>
                      {t(`errors.${message}`)}
                    </div>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-block">
                      <label className="col-form-label">{t('Password')}</label>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <div
                            className="pass-group"
                            style={{ position: "relative" }}
                          >
                            <input
                              className={`form-control`}
                              type={passwordEye ? "password" : "text"}
                              {...field}
                              autoComplete="false"
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={() => setPasswordEye(!passwordEye)}
                              className={`eye-icon fa toggle-password ${
                                passwordEye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                        )}
                        defaultValue=""
                      />
                      <span className="text-danger d-block">
                        {errors.password && <span>{errors.password.message}</span>}
                        {serverErrors.password && <p>{serverErrors.password[0]}</p>}
                      </span>
                    </div>
                    <div className="input-block">
                      <label className="col-form-label">{t('Confirm Password')}</label>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field }) => (
                          <div
                            className="pass-group"
                            style={{ position: "relative" }}
                          >
                            <input
                              className={`form-control`}
                              type={confirmPasswordEye ? "password" : "text"}
                              {...field}
                              autoComplete="false"
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={() =>
                                setConfirmPasswordEye(!confirmPasswordEye)
                              }
                              className={`fa toggle-password ${
                                confirmPasswordEye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                        )}
                        defaultValue=""
                      />
                      <span className="text-danger d-block">
                        {errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}
                      </span>
                    </div>
                    <div className="input-block text-center">
                      <button
                        className="btn btn-primary account-btn"
                        type="submit">
                        {t('Reset Password')}
                      </button>
                    </div>
                    {/* <div className="account-footer">
                      <p>
                        {t('Remember your password?')} <Link to="/">{t('Login')}</Link>
                      </p>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Cover Section */}
      <div className="cover-wrapper">
          <div className="mb-12 cover-div">
            <img src={CoverPhoto} alt="Omninexus"/>
          </div>
      </div>
      {/* End Cover Section */}
    </div>
  );
};
export default ResetPassword;
