//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : FirstTimeLogin.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (12 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240712     Jecyline     create FirstTimeLogin.jsx
//* 20240715     Jecyline     postal code to be numeric, company email change to email, email is readonly
//* 20240716     Jecyline     fix wrong placeholder for company services/products, add validation check for country code, fix serverError bug
//* 20240717     Jecyline     get REACT_APP_CAPTCHA_SITE_KEY from env
//* 20240718     Jecyline     language selection module
//* 20240801     Glynnis      add staging link terms
//* 20240802     Jecyline     change /marvis/terms to /terms
//* 20240806     Jecyline     add reset recaptcha when failed to get response
//* 20240822     Jecyline     use tarun api to get country list
//* 20240919     Chris        Change the company form to brand form and integrate with web scrapper API
//* 20240925     Jecyline     add upload logo, display color scheme, pass new fields to backend
//* 20240929     Glynnis      add color picker
//
//***************************************************************************/
import React, { useRef, useEffect, useState } from "react";
import * as ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import axios from "axios";
import { api } from '../../../services/api';
import Breadcrumbs from "../../../components/Breadcrumbs";
import { facebookicon } from "../../../Routes/ImagePath";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import ReCAPTCHA from 'react-google-recaptcha'
import useAxiosInterceptors from '../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../layout/LanguageSelector';
import API_ENDPOINTS from '../../../api/endpoints';
import InputColor from 'react-input-color';
import { isValidUrl } from '../../../utils/urlUtils';

const FirstTimeLogin = () => {

  const [color, setColor] = useState({});
  const [isBrandScrapLoading, setIsBrandScrapLoading ] = useState(false);
  const [isSubmitting, setIsSubmitting ] = useState(false);

  // Call the custom hook to set up interceptors
  useAxiosInterceptors(); 
  const { t } = useTranslation();

  // Define the validation schema using Yup
  const [isUrlError, setUrlError] = useState(false);
  const validationSchema = Yup.object().shape({
      company_website: Yup.string().required(`${t('company website')}${t(' is required')}`).url(`${t('errors.INVALID_URL_FORMAT')}`),
      company_name: Yup.string().required(`${t('Brand name')}${t(' is required')}`),
      company_short_desc: Yup.string().required(`${t('Brand description')}${t(' is required')}`),
      brand_business_type: Yup.string().required(`${t('Brand business type')}${t(' is required')}`),
      logo_url: Yup.string().nullable(),
      logo_file: Yup.mixed().nullable(),
      // Add a custom validation rule
      logo_validation: Yup.string().test('logo-required', 
        (value, context) => {
          const { logo_url, logo_file } = context.parent; // Access other fields
          if (!logo_url && !logo_file) {
            return new Yup.ValidationError(
              `${t('Brand Logo')} ${t('is required')}`,
              null,
              'logo_validation' // Use this field name for error
            );
          }
          return true;
        }
      ),
      // brand_colors: Yup.string().required(`${t('Brand color scheme')}${t(' is required')}`),
      isTermsChecked: Yup.bool()
      .oneOf([true], `${t('You must agree to the terms and conditions')}`)
      .required(`${t('You must agree to the terms and conditions')}`),
  });

  // Initialize the form with react-hook-form and Yup resolver
  const { 
    control, 
    handleSubmit, 
    setValue, 
    formState: { errors } 
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: '',
      status: '',
      company_website:'',
      company_name: '',
      company_short_desc: '',
      brand_business_type: '',
      logo_url: '',
      logo_file: '',
      brand_colors: [],
      isTermsChecked: false,
    }
  });

  const navigate = useNavigate();
  const recaptcha = useRef();

  const [serverErrors, setServerErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [termsError, setTermsError] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  // const [colorScheme, setColorScheme] = useState('');

  // const handleChange = (e) => {
  //   setColorScheme(e.target.value);
  // };

  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MARVIS_AI}/countries`);
        // console.log('response',response);

        const countryList = response.data.all_countries;
        
        const countries = countryList.map(country => ({
          value: country.cca2,
          label: country.name.common,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
        
        setCountryOptions(countries);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCountryList();

  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await api.get(`/company`);
      const data = response.data;

      if (data.status == 3) navigate('/dashboard'); //for user who with status 3 redirect to dashboard as they alrady fill in
      setValue('id', data.id);
      setValue('status', data.status);
      setValue('username', data.name || '');

    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  /* Call Web Scrapper API */

  const [image, setImage] = useState(null);  // State to store image URL from API
  const [brandUrl, setBrandUrl] = useState('');
  const [brand1, setBrandColor1] = useState('');
  const [brand2, setBrandColor2] = useState('');
  const [brand3, setBrandColor3] = useState('');
  const [brand4, setBrandColor4] = useState('');
  const [brand5, setBrandColor5] = useState('');
  const [brand6, setBrandColor6] = useState('');

  const fileInputRef = useRef(null); // Ref to access the file input

  const aiWebScrapperApi = async () => {
    setUrlError(false);
    setIsBrandScrapLoading(true);
    console.log('aiWebScrapperApi: brandUrl', brandUrl);

    if(brandUrl === '')
    {
      alert('Kindly input an website URL.');
      setIsBrandScrapLoading(false);
    }
    else
    {
      try{
        const scrapping = await api.get(API_ENDPOINTS.MARVIS_WEB_SCRAPPER(brandUrl)).then(response => {setBrandValue(response.data)});
        setIsBrandScrapLoading(false);
        return scrapping;
      } catch (error) {
        setUrlError(true);
        setIsBrandScrapLoading(false);
      }
    }
  };

  const setBrandValue = (data) => {
    console.log('scrap data', data);
    resetBrandValue();

    setValue('company_name', data.brand_name || '');
    setValue('company_short_desc', data.brand_description || '');
    setValue('brand_business_type', data.business_type || '');

    //handle image url
    if (data.logo_images && data.logo_images.length > 0) {
      const logos = data.logo_images; // Get logo images array

      // Loop through each logo image URL
      for (const logoImageUrl of logos) {
        if (isValidUrl(logoImageUrl)) {
          setValue('logo_url', logoImageUrl || '');
          handleImageChange('logo_url', logoImageUrl || '');
          setImage(logoImageUrl || null);
          break;
        }
      }
    }
    
    setBrandColor1(data.brand_colors[0]);
    setBrandColor2(data.brand_colors[1]);
    setBrandColor3(data.brand_colors[2]);
    setBrandColor4(data.brand_colors[3]);
    setBrandColor5(data.brand_colors[4]);
    setBrandColor6(data.brand_colors[5]);

    setValue('brand_colors', data.brand_colors.slice(0, 6));
    setIsBrandScrapLoading(false);
  };

  const resetBrandValue = () => {
    console.log('resetBrandValue');

    setValue('company_name', '');
    setValue('company_short_desc', '');
    setValue('brand_business_type', '');

    setValue('logo_url', '');
    handleImageChange('logo_url', '');
    setImage(null);

    setBrandColor1();
    setBrandColor2();
    setBrandColor3();
    setBrandColor4();
    setBrandColor5();
    setBrandColor6();

    setValue('brand_colors', []);
  };

  const handleImageChange = (type, item) => {

    if(type === 'logo_url'){
      if(item && item !== '') {
        setImage(item);
        setValue('logo_file', '');

        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Clear the file input
        }
      }
    } else {
      if (item && item.length > 0) {  
        const fileObj = item[0];  
        const previewUrl = URL.createObjectURL(fileObj);
        setImage(previewUrl);
      } else {
        setImage(null);
      }
      setValue('logo_url', '');
    }
  }

  // const addColor = () => {
  //   if (brandColors.length < 8) {
  //     setBrandColors([...brandColors, '#000000']);
  //   }
  // };

  // const removeColor = (index) => {
  //   const newColors = brandColors.filter((_, i) => i !== index);
  //   setBrandColors(newColors);
  //   setValue('brand_colors', newColors); // Update the form state
  // };

  // const handleColorChange = (index, value) => {
  //   const newColors = [...brandColors];
  //   newColors[index] = value;
  //   setBrandColors(newColors);
  //   setValue('brand_colors', newColors); // Update the form state
  // };

  /* End Call Web Scrapper API */

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    console.log('submit data', data);
    try { // verify captcha
      const captchaToken = recaptcha.current.getValue()
      if (!captchaToken) {
        setIsSubmitting(false);
        setCaptchaError(`${t('Please verify the reCAPTCHA')}`);
        setTimeout(() => setCaptchaError(''), 5000);
      } else {
        await api.post(`/verify-captcha`, {captchaToken});

        const formData = new FormData();
        // formData.append('id', data.id);
        // formData.append('status', data.status);
        formData.append('company_name', data.company_name);
        formData.append('company_website', data.company_website);
        formData.append('company_short_desc', data.company_short_desc);
        formData.append('brand_business_type', data.brand_business_type);
        formData.append('logo_url', data.logo_url);
        formData.append('logo_file', data.logo_file);
        formData.append('brand_colors', data.brand_colors);

        console.log('formData', formData);

        try { //update company info
          await api.post(`/brand`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setIsSubmitting(false);
          navigate('/dashboard');
        } catch (error) {
          setIsSubmitting(false);

          recaptcha.current.reset();
          if (error.response && error.response.data) {
            setServerErrors(error.response.data);
          } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setErrorMessage(`${t('An error occurred while updating company info')}`);
            setTimeout(() => setErrorMessage(''), 5000); 
          }
        }
      }
    } catch (error) {  
      setIsSubmitting(false);

      console.log(error);  
      recaptcha.current.reset();  
      setCaptchaError(error.response.data.error);
      setTimeout(() => setCaptchaError(''), 5000);
    }
  };

  return (
    <>
      <div className="page-wrapper-no-sidebar">
        {/* Page Content */}
        <div className="content container-fluid company-info">

          <div>
            <div className="row justify-content-center">
              <div className="col-12 d-flex">
                  <div className=" profile-box flex-fill">
                    <div className="m-2">
                      <LanguageSelector />
                    </div>
                  </div>
              </div>
            </div>
          </div>

          {/* Brand Info Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}

            <div className="row justify-content-center">
              {/* Website */}
              <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-center align-items-center">
                <div className="card profile-box flex-fill">
                  <div className="card-body">
                    <h3 className="card-title">
                        {t('Brand Setup')}{" "}
                        </h3>
                    <div className="row">
                      <div className="col-12 col-md-9">
                        <div className="input-block mb-3">
                          <Controller
                            name="company_website"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control`}
                                type="text"
                                placeholder={`${t('Enter your')} ${t('company website')}`}
                                {...field}
                                autoComplete="false"
                                onChange={(e) => {
                                  field.onChange(e); // This updates the react-hook-form state
                                  setBrandUrl(e.target.value); // This sets your additional state
                                }}
                                disabled={isBrandScrapLoading}
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.company_website?.message}
                            {serverErrors.company_website && <p>{serverErrors.company_website[0]}</p>}
                          </span>
                          <span className="text-danger d-block">
                            {isUrlError && (
                              t('Oops! It looks like there’s a problem with the URL. Please try a different one.')
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 ">
                        <div className="input-block mb-3 d-flex align-items-center justify-content-center">
                          <button className="btn btn-primary company-btn" type="button" onClick={aiWebScrapperApi} disabled={isBrandScrapLoading}>
                            {/* {t('Import')} */}
                            {isBrandScrapLoading ? t('Importing...') : t('Import')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {/* Brand Information */}
              <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-center align-items-center">
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <div className="input-block mb-3">
                        <label className="col-form-label">{t('Brand Name')}</label>
                        <Controller
                          name="company_name"
                          control={control}
                          render={({ field }) => (
                            <input
                              // className={`form-control`}
                              className={`form-control ${isBrandScrapLoading ? 'loading' : ''}`}
                              type="text"
                              placeholder={`${t('Enter your')} ${t('brand name')}`}
                              {...field}
                              autoComplete="false"
                              disabled={isBrandScrapLoading}
                            />
                          )}
                        />
                        <span className="text-danger d-block">
                          {errors.company_name?.message}
                          {serverErrors.company_name && <p>{serverErrors.company_name[0]}</p>}
                        </span>
                      </div>
                      
                      <div className="input-block mb-3">
                        <label className="col-form-label">{t('Brand Description')}</label>
                        <Controller
                          name="company_short_desc"
                          control={control}
                          render={({ field }) => (
                            <textarea
                              // className={`form-control`}
                              className={`form-control ${isBrandScrapLoading ? 'loading' : ''}`}
                              placeholder={`${t('Enter your')} ${t('brand description')}`}
                              {...field}
                              rows={4}
                              autoComplete="off"
                              disabled={isBrandScrapLoading}
                            />
                          )}
                        />
                        <span className="text-danger d-block">
                          {errors.company_short_desc?.message}
                          {serverErrors.company_short_desc && <p>{serverErrors.company_short_desc[0]}</p>}
                        </span>
                      </div>

                      <div className="input-block mb-3">
                        <label className="col-form-label">{t('Business Type')}</label>
                        <Controller
                          name="brand_business_type"
                          control={control}
                          render={({ field }) => (
                            <input
                              // className={`form-control`}
                              className={`form-control ${isBrandScrapLoading ? 'loading' : ''}`}
                              type="text"
                              placeholder={`${t('Enter your')} ${t('business type')}`}
                              {...field}
                              autoComplete="false"
                              disabled={isBrandScrapLoading}
                            />
                          )}
                        />
                        <span className="text-danger d-block">
                          {errors.brand_business_type?.message}
                          {serverErrors.brand_business_type && <p>{serverErrors.brand_business_type[0]}</p>}
                        </span>
                      </div>

                      <div className="input-block mb-3">
                        <label className="col-form-label">{t('Brand Logo')}</label>
                        <Controller
                          name="logo_url"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control`}
                              type="hidden"
                              {...field}
                              autoComplete="false"
                              onChange={(e) => {
                                field.onChange(e.target.value); // Update the logo_url state
                                handleImageChange('logo_url', e.target.value); // Pass the URL to handleImageChange
                              }}                
                            />
                          )}
                        />

                        <Controller
                          name="logo_file"
                          control={control}
                          ref={fileInputRef}
                          render={({ field: { onChange } }) => (
                            <input
                              // className={`form-control`}
                              className={`form-control ${isBrandScrapLoading ? 'loading' : ''}`}
                              type="file"
                              placeholder={`${t('Enter your')} ${t('brand logo')}`}
                              accept="image/*"
                              onChange={(e) => {
                                onChange(e.target.files[0]); // Update the form state with the selected file
                                handleImageChange('logo_file', e.target.files); // Pass the file to handleImageChange
                              }}
                              disabled={isBrandScrapLoading}
                            />
                          )}
                        />
                        
                        {image && (
                          <div className="col-12 col-md-12 d-flex align-items-center justify-content-center">
                            <img src={image} alt="Brand Logo" style={{ margin:'5px', width: '150px', height: '150px', objectFit: 'contain' }} />
                          </div>
                        )}

                        <span className="text-danger d-block">
                          {errors.logo_validation?.message}

                          {serverErrors.logo_url && <p>{serverErrors.logo_url[0]}</p>}
                          {serverErrors.logo_file && <p>{serverErrors.logo_file[0]}</p>}
                        </span>
                      </div>

                      {/* <div className="input-block mb-3">
                      <label className="col-form-label">{t('Brand Colors:')}</label>
                      {brandColors.map((color, index) => (
                          <div key={index} className="d-flex mb-2">
                            <input
                              type="text"
                              value={color}
                              onChange={(e) => handleColorChange(index, e.target.value)}
                              className={`form-control me-2 ${errors.brandColors ? 'is-invalid' : ''}`}
                              placeholder={`Color ${index + 1}`}
                            />
                            <button type="button" onClick={() => removeColor(index)}>Remove</button>
                          </div>
                        ))}
                        <button type="button" onClick={addColor}>Add Color</button>
                        {errors.brandColors && <span className="text-danger">{errors.brandColors.message}</span>}
                      </div> */}

                      <div className="input-block mb-3">
                        <label className="col-form-label">{t('Brand Color Scheme')}</label>
                          <div className="col-12 col-md-12 d-flex align-items-center justify-content-center">
                            {/* <div className="col-2 d-flex align-items-center justify-content-center">
                              <div style={{backgroundColor:brand1, width: '50px', height: '50px', borderRadius:'10px', border: '2px solid white'}}></div>
                            </div> 
                            <div className="col-2 d-flex align-items-center justify-content-center">
                              <div style={{backgroundColor:brand2, width: '50px', height: '50px', borderRadius:'10px', border: '2px solid white'}}></div>
                            </div> 
                            <div className="col-2 d-flex align-items-center justify-content-center">
                              <div style={{backgroundColor:brand3, width: '50px', height: '50px', borderRadius:'10px', border: '2px solid white'}}></div>
                            </div> 
                            <div className="col-2 d-flex align-items-center justify-content-center">
                              <div style={{backgroundColor:brand4, width: '50px', height: '50px', borderRadius:'10px', border: '2px solid white'}}></div>
                            </div> 
                            <div className="col-2 d-flex align-items-center justify-content-center">
                              <div style={{backgroundColor:brand5, width: '50px', height: '50px', borderRadius:'10px', border: '2px solid white'}}></div>
                            </div> 
                            <div className="col-2 d-flex align-items-center justify-content-center">
                              <div style={{backgroundColor:brand6, width: '50px', height: '50px', borderRadius:'10px', border: '2px solid white'}}></div>
                            </div>  */}
                            {brand1 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand1}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand1,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand2 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand2}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand2,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand3 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand3}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand3,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand4 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand4}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand4,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand5 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand5}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand5,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand6 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand6}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand6,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                        </div>

                        <Controller
                          name="brand_colors"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control`}
                              type="hidden"
                              {...field}
                            />
                          )}
                        />

                        <span className="text-danger d-block">
                          {errors.brand_colors?.message}
                          {serverErrors.brand_colors && <p>{serverErrors.brand_colors[0]}</p>}
                        </span>
                      </div>
                      {/* Connection Facebook Accounts */}
                      {/*<div className="row">
                        <div className="col-12 col-md-8 d-flex align-items-center justify-content-center">
                            <div className="card-body">
                              <h3 className="card-title">
                                {t('Connect Facebook Accounts')}{" "}
                              </h3>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
                          <button type="button" className="account-icon-button">
                                <img src={facebookicon} alt="Facebook" className="account-img-icon"/>
                                {t('Facebook')}
                          </button>
                        </div>
                      </div>*/}
                      {/* Connection Facebook Accounts end */}

                      <hr/>

                      <div className="row justify-content-center">
                          <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                            <div className="card">
                              <div className="card-body">
                                {/* CAPTCHA */}
                                <div className="input-block mb-12">
                                  <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} />
                                  
                                    {captchaError && (
                                      <span className="text-danger d-block">
                                        {captchaError}
                                      </span>
                                    )}
                                </div>
                                <div className="input-block mb-12">
                                  <Controller
                                    name="isTermsChecked"
                                    control={control}
                                    render={({ field: { value, onChange, onBlur, name } }) => (
                                      <input
                                        type="checkbox"
                                        name={name}
                                        checked={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        style={{ marginRight: '18px' }}
                                      />
                                    )}
                                    defaultValue={false}
                                  />
                                  <label htmlFor="terms">
                                    {t('I agree to the')}{' '}
                                    <a
                                      href="/terms"
                                      // href="/terms"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t('Terms and Conditions')}
                                    </a>
                                  </label>
                                  <span className="text-danger d-block">
                                    {errors.isTermsChecked?.message}
                                    {serverErrors.isTermsChecked && <p>{serverErrors.isTermsChecked[0]}</p>}
                                  </span>
                                </div>
                                <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                                  <button type="submit" className="btn btn-primary company-btn"  disabled={isBrandScrapLoading||isSubmitting}>
                                    {isSubmitting ? t('Submitting...') : t('Submit')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
              </div>
              {/* Brand Information end */}
            </div>
          </form>
          {/* Brand Info Form end */}
        </div>
        {/* /Page Content end */}
      </div>
    </>
  );
};

export default FirstTimeLogin;
