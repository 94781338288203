//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : chatBrands.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (12 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241003     Glynnis     create chatBrands.jsx

//
//***************************************************************************/
import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { api } from '../../../services/api';
import useAxiosInterceptors from '../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../layout/LanguageSelector';
import API_ENDPOINTS from '../../../api/endpoints';
import InputColor from 'react-input-color';
import { useDispatch } from 'react-redux';
import {
  chatClickExternalAction,
} from '../../../store/actions/chatActions';
import moment from 'moment';
import { isValidUrl } from '../../../utils/urlUtils';

const ChatBrandScrap = () => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();

  const location = useLocation();
  const dispatch = useDispatch();

  const { chat_name, campaign_type, brand_from } = location.state || {};

  const [color, setColor] = useState({});
  const [isBrandScrapLoading, setIsBrandScrapLoading ] = useState(false);
  const [isSubmitting, setIsSubmitting ] = useState(false);

  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({
    // company_website: Yup.string().required(`${t('company website')}${t(' is required')}`).url(`${t('errors.INVALID_URL_FORMAT')}`),
    chat_brand_name: Yup.string().required(`${t('Brand name')}${t(' is required')}`),
    chat_brand_desc: Yup.string().required(`${t('Brand description')}${t(' is required')}`),
    chat_brand_business_type: Yup.string().required(`${t('Brand business type')}${t(' is required')}`),
    chat_logo_url: Yup.string().nullable(),
    chat_logo_file: Yup.mixed().nullable(),
    // Add a custom validation rule
    chat_logo_validation: Yup.string().test('logo-required', 
      (value, context) => {
        const { chat_logo_url, chat_logo_file } = context.parent; // Access other fields
        if (!chat_logo_url && !chat_logo_file) {
          return new Yup.ValidationError(
            `${t('Brand Logo')} ${t('is required')}`,
            null,
            'chat_logo_validation' // Use this field name for error
          );
        }
        return true;
      }
    ),
    // chat_brand_colors: Yup.string().required(`${t('Brand color scheme')}${t(' is required')}`),
  });

  // Initialize the form with react-hook-form and Yup resolver
  const { 
    control, 
    handleSubmit, 
    setValue, 
    formState: { errors } 
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      company_website:'',
      chat_brand_name: '',
      chat_brand_desc: '',
      chat_brand_business_type: '',
      chat_logo_url: '',
      chat_logo_file: '',
      chat_brand_colors: [],
    }
  });

  const navigate = useNavigate();
  // const recaptcha = useRef();

  const [serverErrors, setServerErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const [captchaError, setCaptchaError] = useState('');

  /* Call Web Scrapper API */

  const [image, setImage] = useState(null);  // State to store image URL from API
  const [brandUrl, setBrandUrl] = useState('');
  const [brand1, setBrandColor1] = useState('');
  const [brand2, setBrandColor2] = useState('');
  const [brand3, setBrandColor3] = useState('');
  const [brand4, setBrandColor4] = useState('');
  const [brand5, setBrandColor5] = useState('');
  const [brand6, setBrandColor6] = useState('');

  const fileInputRef = useRef(null); // Ref to access the file input

  const aiWebScrapperApi = () => {
    setIsBrandScrapLoading(true);
    console.log('aiWebScrapperApi: brandUrl', brandUrl);

    if(brandUrl === '')
    {
      alert('Kindly input an website URL.');
      setIsBrandScrapLoading(false);
    }
    else
    {
      setValue('company_website', brandUrl || '');

      return api.get(API_ENDPOINTS.MARVIS_WEB_SCRAPPER(brandUrl))
      .then(response => {setBrandValue(response.data)})
      .catch(error => {
        console.log('error', error);

        // Handle network or other errors that occur during the API request
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Error response:', error.response.data);
          console.error('Status code:', error.response.status);
        } else if (error.request) {
          // Request was made but no response received
          console.error('Error request:', error.request);
        } else {
          // Something else happened in setting up the request
          console.error('Error message:', error.message);
        }

        setIsBrandScrapLoading(false);

        window.scrollTo({ top: 0, behavior: 'smooth' });
        setErrorMessage(`${t('An error occurred while import the data. Please retry.')}`);
        setTimeout(() => setErrorMessage(''), 5000); 
      });
    }
  };

  const setBrandValue = (data) => {
    console.log('scrap data', data);
    resetBrandValue();

    setValue('chat_brand_name', data.brand_name || '');
    setValue('chat_brand_desc', data.brand_description || '');
    setValue('chat_brand_business_type', data.business_type || '');

    //handle image url
    if (data.logo_images && data.logo_images.length > 0) {
      const logos = data.logo_images; // Get logo images array

      // Loop through each logo image URL
      for (const logoImageUrl of logos) {
        if (isValidUrl(logoImageUrl)) {
          setValue('chat_logo_url', logoImageUrl || '');
          handleImageChange('chat_logo_url', logoImageUrl || '');
          setImage(logoImageUrl || null);
          break;
        }
      }
    }
    
    setBrandColor1(data.brand_colors[0]);
    setBrandColor2(data.brand_colors[1]);
    setBrandColor3(data.brand_colors[2]);
    setBrandColor4(data.brand_colors[3]);
    setBrandColor5(data.brand_colors[4]);
    setBrandColor6(data.brand_colors[5]);

    setValue('chat_brand_colors', data.brand_colors.slice(0, 6));
    setIsBrandScrapLoading(false);
  };

  const resetBrandValue = () => {
    console.log('resetBrandValue');

    setValue('chat_brand_name', '');
    setValue('chat_brand_desc', '');
    setValue('chat_brand_business_type', '');

    setValue('chat_logo_url', '');
    handleImageChange('chat_logo_url', '');
    setImage(null);

    setBrandColor1();
    setBrandColor2();
    setBrandColor3();
    setBrandColor4();
    setBrandColor5();
    setBrandColor6();

    setValue('brand_colors', []);
  };

  const handleImageChange = (type, item) => {

    if(type === 'chat_logo_url'){
      if(item && item !== '') {
        setImage(item);
        setValue('chat_logo_file', '');

        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Clear the file input
        }
      }
    } else {
      if (item && item.length > 0) {  
        const fileObj = item[0];  
        const previewUrl = URL.createObjectURL(fileObj);
        setImage(previewUrl);
      } else {
        setImage(null);
      }
      setValue('chat_logo_url', '');
    }
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    console.log('submit data', data);
    console.log('submit chat_name', chat_name);
    console.log('submit campaign_type', campaign_type);

    try {
      let introduction_text = t('Hi I am Marvis, a digital marketing virtual assistant.');
      //set reply date and time
      const now = moment(); // Get current time
      const replyDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
      const replyTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

      const formData = new FormData();
      formData.append('chat_name', chat_name ?? '');
      formData.append('campaign_type', campaign_type ?? 'Normal');
      formData.append('brand_from', brand_from ?? 'Other');
      formData.append('chat_brand_name', data.chat_brand_name);
      formData.append('chat_brand_desc', data.chat_brand_desc);
      formData.append('chat_brand_business_type', data.chat_brand_business_type);
      formData.append('chat_logo_url', data.chat_logo_url);
      formData.append('chat_logo_file', data.chat_logo_file);
      formData.append('chat_brand_colors', data.chat_brand_colors);
      formData.append('introduction_text', introduction_text);
      formData.append('reply_date', replyDate);
      formData.append('reply_time', replyTime);
      console.log('formData', formData);

        
      const response = await api.post(`/chat/create-with-brand-data`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('response', response);
      console.log('chat id', response.data.chat.id);

      setIsSubmitting(false);

      /////////////////////////////////////
      // chatAction: chatClickExternalAction
      dispatch(chatClickExternalAction({chatId: response.data.chat.id, from: 'BrandScrap'}));
      navigate(`/chat`);
    } catch (error) {  
      setIsSubmitting(false);

      console.log('error', error);
      if (error.response && error.response.data) {
        setServerErrors(error.response.data);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setErrorMessage(`${t('An error occurred while updating company info')}`);
        setTimeout(() => setErrorMessage(''), 5000); 
      }
    }
  };

  return (
    <>
      <div className="page-wrapper-no-sidebar">
        {/* Page Content */}
        <div className="content container-fluid company-info">

          <div>
            <div className="row justify-content-center">
              <div className="col-12 d-flex">
                  <div className=" profile-box flex-fill">
                    <div className="m-2">
                      <LanguageSelector />
                    </div>
                  </div>
              </div>
            </div>
          </div>

          {/* Brand Info Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}

            <div className="row justify-content-center">
              {/* Website */}
              <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-center align-items-center">
                <div className="card profile-box flex-fill">
                  <div className="card-body">
                    <h3 className="card-title">
                        {t('New Brand Setup')}{" "}
                        </h3>
                    <div className="row">
                      <div className="col-12 col-md-9">
                        <div className="input-block mb-3">
                          <Controller
                            name="company_website"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control`}
                                type="text"
                                placeholder={`${t('Enter your ')} ${t('company website')}`}
                                {...field}
                                autoComplete="false"
                                onChange={(e) => {
                                  field.onChange(e); // This updates the react-hook-form state
                                  setBrandUrl(e.target.value); // This sets your additional state
                                }}
                                disabled={isBrandScrapLoading}
                              />
                            )}
                          />
                          <span className="text-danger d-block">
                            {errors.company_website?.message}
                            {serverErrors.company_website && <p>{serverErrors.company_website[0]}</p>}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 ">
                        <div className="input-block mb-3 d-flex align-items-center justify-content-center">
                          {/* <button className="btn btn-primary company-btn" type="button" onClick={aiWebScrapperApi}>{t('Import')}</button> */}
                          <button className="btn btn-primary company-btn" type="button" onClick={aiWebScrapperApi} disabled={isBrandScrapLoading}>
                            {isBrandScrapLoading ? t('Importing...') : t('Import')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {/* Brand Information */}
              <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-center align-items-center">
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                      <div className="input-block mb-3">
                        <label className="col-form-label">{t('Brand Name')}</label>
                        <Controller
                          name="chat_brand_name"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${isBrandScrapLoading ? 'loading' : ''}`}
                              type="text"
                              placeholder={`${t('Enter your ')} ${t('brand name')}`}
                              {...field}
                              autoComplete="false"
                              disabled={isBrandScrapLoading}
                            />
                          )}
                        />
                        <span className="text-danger d-block">
                          {errors.chat_brand_name?.message}
                          {serverErrors.chat_brand_name && <p>{serverErrors.chat_brand_name[0]}</p>}
                        </span>
                      </div>
                      
                      <div className="input-block mb-3">
                        <label className="col-form-label">{t('Brand Description')}</label>
                        <Controller
                          name="chat_brand_desc"
                          control={control}
                          render={({ field }) => (
                            <textarea
                              className={`form-control ${isBrandScrapLoading ? 'loading' : ''}`}
                              placeholder={`${t('Enter your ')} ${t('brand description')}`}
                              {...field}
                              rows={4}
                              autoComplete="off"
                              disabled={isBrandScrapLoading}
                            />
                          )}
                        />
                        <span className="text-danger d-block">
                          {errors.chat_brand_desc?.message}
                          {serverErrors.chat_brand_desc && <p>{serverErrors.chat_brand_desc[0]}</p>}
                        </span>
                      </div>

                      <div className="input-block mb-3">
                        <label className="col-form-label">{t('Business Type')}</label>
                        <Controller
                          name="chat_brand_business_type"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${isBrandScrapLoading ? 'loading' : ''}`}
                              type="text"
                              placeholder={`${t('Enter your ')} ${t('business type')}`}
                              {...field}
                              autoComplete="false"
                              disabled={isBrandScrapLoading}
                            />
                          )}
                        />
                        <span className="text-danger d-block">
                          {errors.chat_brand_business_type?.message}
                          {serverErrors.chat_brand_business_type && <p>{serverErrors.chat_brand_business_type[0]}</p>}
                        </span>
                      </div>

                      <div className="input-block mb-3">
                        <label className="col-form-label">{t('Brand Logo')}</label>
                        <Controller
                          name="chat_logo_url"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control`}
                              type="hidden"
                              {...field}
                              autoComplete="false"
                              onChange={(e) => {
                                field.onChange(e.target.value); // Update the chat_logo_url state
                                handleImageChange('chat_logo_url', e.target.value); // Pass the URL to handleImageChange
                              }}                
                            />
                          )}
                        />

                        <Controller
                          name="chat_logo_file"
                          control={control}
                          ref={fileInputRef}
                          render={({ field: { onChange } }) => (
                            <input
                              className={`form-control ${isBrandScrapLoading ? 'loading' : ''}`}
                              type="file"
                              placeholder={`${t('Enter your ')} ${t('brand logo')}`}
                              accept="image/*"
                              onChange={(e) => {
                                onChange(e.target.files[0]); // Update the form state with the selected file
                                handleImageChange('chat_logo_file', e.target.files); // Pass the file to handleImageChange
                              }}
                              disabled={isBrandScrapLoading}
                            />
                          )}
                        />
                        
                        {image && (
                          <div className="col-12 col-md-12 d-flex align-items-center justify-content-center">
                            <img src={image} alt="Brand Logo" style={{ margin:'5px', width: '150px', height: '150px', objectFit: 'contain' }} />
                          </div>
                        )}

                        <span className="text-danger d-block">
                          {errors.chat_logo_validation?.message}

                          {serverErrors.chat_logo_url && <p>{serverErrors.chat_logo_url[0]}</p>}
                          {serverErrors.chat_logo_file && <p>{serverErrors.chat_logo_file[0]}</p>}
                        </span>
                      </div>

                      <div className="input-block mb-3">
                        <label className="col-form-label">{t('Brand Color Scheme')}</label>
                          <div className="col-12 col-md-12 d-flex align-items-center justify-content-center">
                            {brand1 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand1}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand1,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand2 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand2}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand2,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand3 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand3}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand3,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand4 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand4}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand4,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand5 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand5}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand5,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                              {brand6 && (
                                <div className="col-2 d-flex align-items-center justify-content-center">
                                  <InputColor
                                  onChange={setColor}
                                  placement="right"
                                  initialValue={brand6}
                                  style={{ 
                                    width: '45px', 
                                    height: '45px', 
                                    borderRadius:'10px',
                                    backgroundColor:brand6,
                                    border: '2px solid white',
                                  }}
                                  />
                                </div> 
                              )}
                        </div>

                        <Controller
                          name="chat_brand_colors"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control`}
                              type="hidden"
                              {...field}
                            />
                          )}
                        />

                        <span className="text-danger d-block">
                          {errors.chat_brand_colors?.message}
                          {serverErrors.chat_brand_colors && <p>{serverErrors.chat_brand_colors[0]}</p>}
                        </span>
                      </div>
                      
                      <hr/>

                      <div className="row justify-content-center">
                        <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                          <div className="card">
                              <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
                                <button type="submit" className="btn btn-primary company-btn"  disabled={isBrandScrapLoading||isSubmitting}>
                                  {isSubmitting ? t('Submitting...') : t('Submit')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                </div>
              </div>
              {/* Brand Information end */}
            </div>
          </form>
          {/* Brand Info Form end */}
        </div>
        {/* /Page Content end */}
      </div>
    </>
  );
};

export default ChatBrandScrap;
