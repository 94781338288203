//****************************************************************************
//* Filename  : chatContants.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (06 Sept 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240906     Jecyline     Create chatContants.jsx
//* 20240911     Jecyline     restructure constant to targetList and targetOptionsList for new brain flow
//* 20240919     Glynnis      added the options for before inital flow
//* 20240923     Glynnis      added the location options for before inital flow
//* 20240930     Jecyline     added the image_not_satisfy_option, upload_image_options, set_is_image to targetList
//* 20240930     Jecyline     added the campaign_creation_options, no_adslibrary_options, regenerate_copy, regenerate_image, generate_adslib_image to beforeInitial
//* 20241003     Jecyline     add initial_copywriting_strategy
//* 20241004     Jecyline     add create_more_ads, revise_suggested_ads, amend initial_copywriting_strategy - remove underscore
//
//***************************************************************************/

export const targetList = {
  image_satisfaction: "Image Satisfaction",
  image_not_satisfy_option: "Image Not Satisfy Options",
  copywriting_strategy: "Copywriting Strategy",
  revise_complete_ads: "Revise Complete Ads",
  image_sizes: "Image Size",
  image_styles: "Image Style",
  assist_copywriting: "Assist Copywriting",
  assist_image: "Assist Image",
  revise_strategy_copywriting: "Revise Strategy Copywriting",
  generate_image: "Generate Image",
  edit_image_part: "Edit Image",
  upload_image_options: "Upload Image Option",
  set_is_image: "Update selected image",
  create_more_ads: "Create More Ads",
  revise_suggested_ads: "Revise Suggested Ads",
  copywriting_strategy_revise: "Copywriting Strategy Revise",
  copywriting_style_revise: "Copywriting Style Revise",
  ads_suggestion_copy_strategy: "Copywriting Strategy Ads Suggestion",
  adsclone_method: "Ads Clone Method",
  adsclone_continue_url: "Continue with fb link or ads library",
  adsclone_satisfaction: "Adsclone Satisfaction",
  adsclone_improvement: 'Improvement on adsclone generated ads',
  copycraft_option: 'CopyCraft Option',
  copycraft_regen_copy: 'CopyCraft Regenerate Copy',
  copycraft_is_copy: 'CopyCraft Is Copy',
  visualads_option: 'VisualAds Option',
  visualads_regen_img: 'VisualAds Image Regenerate / Edit',
  visualads_is_img: 'VisualAds Is Image',
  visualads_revise_option: 'VisualAds Revise Option',
  sketchspark_is_result: 'SketchSpark Is Result',
  sketchspark_save_new: 'SketchSpark Save New',
  brandboost_is_result: 'Brandboost Is Result',
};

export const targetOptionsList = {
  brandboost_is_result: [
    { id: 'yes', value: 'Yes, i would like to try with another keyword' },
    { id: 'no', value: 'No, i wanna keep this prediction results' },
  ],
  sketchspark_save_new: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No, i would like to regenerate from the same image' },
  ],
  sketchspark_is_result: [
    { id: 'yes', value: 'Yes' },
    { id: 'redo', value: 'No, i would like to redo.' },
    { id: 'regenerate', value: 'No, i would like to regenerate from the same image.' },
  ],
  visualads_option: [
    { id: 'generate_new', value: 'Generate a new image' },
    { id: 'inpainting', value: 'Inpainting' },
    { id: 'change_bg', value: 'Change background' },
  ],
  visualads_revise_option: [
    { id: 'describe', value: 'Describe image' },
    { id: 'inpainting', value: 'Inpainting' },
    { id: 'change_bg', value: 'Change background' },
  ],
  visualads_regen_img: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' },
  ],
  visualads_is_img: [
    { id: 'yes', value: 'I like it, save this image' },
    { id: 'revise', value: 'I wanna revise this' },
    { id: 'other', value: 'I would like to try another option' },
  ],
  copycraft_option: [
    { id: 'image', value: 'Upload image and scan' },
    { id: 'description', value: 'Create Copywriting from your descriptions' },
  ],
  copycraft_regen_copy: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' },
  ],
  copycraft_is_copy: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' },
  ],
  set_is_image: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' },
  ],
  adsclone_method: [
    { id: 'fb_url', value: 'Learn from Ads URL' },
    { id: 'adslib', value: 'Search from Ads Library' },
  ],
  adsclone_continue_url: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' },
  ],
  adsclone_satisfaction: [
    { id: '1', value: 'Yes' },
    { id: '2', value: 'No' },
  ],
  adsclone_improvement: [
    { id: 'improve_image', value: 'Improve Image' },
    { id: 'improve_copy', value: 'Improve Copy' },
  ],
  image_satisfaction: [
    { id: '1', value: 'Yes' },
    { id: '2', value: 'No' },
  ],
  image_not_satisfy_option: [
    { id: '1', value: 'I want to create a new image.' },
    { id: '2', value: 'I would like to regenerate the image.' },
    { id: '3', value: 'I would like to edit this image.' },
    { id: '4', value: 'I do not want an image.' },
  ],
  copywriting_strategy: [
    { id: 'driving conversion', value: 'Driving conversions', description: "Advertise with direct conversion focused approach by focusing on your product and also the problem." },
    { id: 'consultancy', value: 'Consultancy', description: "Offering free consultations encourages business owners to explore customized UI/UX solutions, boosting lead generation." },
    { id: 'time limited offer', value: 'Time limited offer', description: "Creating urgency with a limited-time offer prompts business owners to quickly secure premium UI/UX design services, driving immediate leads." },
    { id: 'discounts', value: 'Discounts', description: "Attracts business owners by offering affordable, high-quality UI/UX design, boosting lead conversions." },
    { id: 'custom strategy', value: 'Custom strategy', description: "" },
    { id: 'no', value: "No, I'm happy with the current copywriting", description: "" },
  ],
  revise_complete_ads: [
    {id: 'revise_copy', value: 'I would like to revise my copywriting.'},
    {id: 'revise_image', value: 'I would like to revise my image.'},
  ],
  copywriting_strategy_revise: [
    { id: 'driving conversion', value: 'Driving conversions', description: "Advertise with direct conversion focused approach by focusing on your product and also the problem." },
    { id: 'consultancy', value: 'Consultancy', description: "Offering free consultations encourages business owners to explore customized UI/UX solutions, boosting lead generation." },
    { id: 'time limited offer', value: 'Time limited offer', description: "Creating urgency with a limited-time offer prompts business owners to quickly secure premium UI/UX design services, driving immediate leads." },
    { id: 'discounts', value: 'Discounts', description: "Attracts business owners by offering affordable, high-quality UI/UX design, boosting lead conversions." },
    { id: 'custom strategy', value: 'Custom strategy', description: "" },
    { id: 'no', value: 'No need strategy', description: "" },
  ],
  copywriting_style_revise: [
    { id: 'AIDA', value: 'AIDA', description: 'Attention, Interest, Desire, Action' },
    { id: 'PAS', value: 'PAS', description: 'Problem, Agitate, Solution' },
    { id: '4Cs', value: '4Cs', description: 'Clear, Concise, Compelling, Credible' },
    { id: 'FAB', value: 'FAB', description: 'Features, Advantages, Benefits' },
    { id: 'ACC', value: 'ACC', description: 'Awareness, Comprehension, Conversion' },
    { id: 'SLAP', value: 'SLAP', description: 'Stop, Look, Act, Purchase' },
  ],
  image_sizes: [
    {id: 'square_hd', value: 'Square image (Recommended)', description: "1:1 aspect ratio, perfect for square social media posts."},
    {id: 'landscape_16_9', value: 'Landscape image', description: "16:9 ratio, perfect for landscape content on various platforms."},
    {id: 'portrait_16_9', value: 'Story image', description: "9:16 ratio, ideal for stories, reels, and vertical content."},
    {id: 'portrait_4_3', value: 'Portrait image', description: "3:4 ratio, great for social media feeds that prefer vertical space."},
  ],
  image_styles: [
    {id: 'realistic', value: 'Realistic (Recommended)', description: "A realistic image looks like a real-life photo, with accurate details and colors."},
    {id: 'film noir', value: 'Film Noir', description: "A film noir image has a dark, dramatic look, usually in black and white."},
    {id: 'cartoon', value: 'Cartoon', description: "A cartoon image is a simplified, colorful drawing with exaggerated features."},
    {id: 'comic', value: 'Comic', description: "A comic image is a bold, colorful drawing with strong lines, often used in storytelling."},
    {id: 'painting', value: 'Painting', description: "A painting image is created with brushstrokes, showing artistic textures and colors."},
    {id: 'sketch', value: 'Sketch', description: "A sketch image is a quick, rough drawing with simple lines and minimal detail."},
    {id: 'pixel art', value: 'Pixel Art', description: "Pixel art is a digital image made up of small, square pixels, creating a retro, blocky look."},
    {id: 'studio shot', value: 'Studio Shot', description: "A studio shot is a professionally lit and posed photo taken in a controlled environment."},
    {id: 'surreal', value: 'Surreal', description: "A surreal image blends reality and fantasy, creating dreamlike, unusual scenes."},
  ],
  assist_copywriting: [
    { id: '1', value: 'Yes' },
    { id: '2', value: 'No' },
  ],
  assist_image: [
    { id: '1', value: 'Yes' },
    { id: '2', value: 'No' },
  ],
  revise_strategy_copywriting: [
    { id: '1', value: 'Yes' },
    { id: '2', value: "No, I'm happy with this copywriting" },
  ],
  generate_image: [
    { id: '1', value: 'Yes' },
    { id: '2', value: 'No' },
  ],
  edit_image_part: [
    {id: 'edit_image_part_mask', value: 'I want to change specific item' , reply : 'Describe which item you wish to change to?'},
    {id: 'edit_image_part_background', value: 'I would like to change my background' , reply : 'Describe the type of background you wish to change to?'},
  ],
  upload_image_options: [
    {id: 'edit_image_part_mask', value: 'I want to change specific item.'},
    {id: 'edit_image_part_background', value: 'I would like to change my background.'},
    {id: 'regenerate_image', value: 'I would like to regenerate this image.'},
  ],
  create_more_ads: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' },
  ],
  ads_suggestion_copy_strategy: [
    { id: 'driving conversion', value: 'Driving conversions', description: "Advertise with direct conversion focused approach by focusing on your product and also the problem." },
    { id: 'consultancy', value: 'Consultancy', description: "Offering free consultations encourages business owners to explore customized UI/UX solutions, boosting lead generation." },
    { id: 'time limited offer', value: 'Time limited offer', description: "Creating urgency with a limited-time offer prompts business owners to quickly secure premium UI/UX design services, driving immediate leads." },
    { id: 'discounts', value: 'Discounts', description: "Attracts business owners by offering affordable, high-quality UI/UX design, boosting lead conversions." },
    { id: 'custom strategy', value: 'Custom strategy', description: "" },
    { id: 'no', value: 'No need strategy', description: "" },
  ],
  revise_suggested_ads: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' },
  ],
};

export const editModeOptions = [
  { id: '1', value: 'Generate New Image' }, // txtToImage
  { id: '2', value: 'Regenerate Image' }, // objReg -> txtToImage
  { id: '3', value: 'Image Edit' }, // canva
  { id: '4', value: 'Remove Background' },
  { id: '5', value: 'Change Background' },
  { id: '6', value: 'Change Subject' }, // mask -> inpainting
  { id: '7', value: 'Generate Copywriting' }, // chatbot
];

export const beforeInitial = {
  brands_options: "Brands selections",
  brand_platform_options: "Brands platform selections",
  brand_marketing_obj: "Brands marketing objectives",
  brand_ads_location: "Brands advertisement location based",
  smartscan: "Smart Scan",
  smartscan_graph: "Smart Scan Graph",
  campaign_creation_options: "Campaign creation selections",
  no_adslibrary_options: "No ads found",
  regenerate_copy: "Regenerate Ads Library Copy",
  regenerate_image: "Regenerate Ads Library Image",
  generate_adslib_image: "Generate Ads Library Image",
  initial_copywriting_strategy: "Initial Copywriting Strategy",
  selection_ss_al: "Selection for smart scan or ads library",
  konva: "Selection for konva"
};

export const beforeInitialOptions = {
  brands_options: [
    { id: 'yes', value: 'Yes' }, // get info from user company info
    { id: 'no', value: <a href="https://staging.omninexus.io/chatbrands">New brands import</a> } // brand scraper
  ],
  brand_platform_options: [
    { id: 'facebook', value: 'Facebook' }, // facebook ads
    { id: 'instagram', value: 'Instagram' } // instagram
  ],
  brand_marketing_obj: [
    { id: 'awareness', value: 'Awareness', description: 'Build recognition of your brand, product, or service among your target audience.' }, // marketing objective: awareness
    { id: 'traffic', value: 'Traffic', description: 'Increase the number of visitors to your website, app, or physical store.' }, // marketing objective: traffic
    { id: 'engagement', value: 'Engagement', description: 'Foster interaction and meaningful relationships with your audience, such as likes, comments, and shares on social media.' }, // marketing objective: engagement
    { id: 'sales', value: 'Sales', description: 'Convert potential customers into actual buyers, driving revenue.' }, // marketing objective: sales
    { id: 'leads', value: 'Leads', description: 'Generate potential customers by encouraging people to share contact information or show interest.' }, // marketing objective: leads
    { id: 'app_promotion', value: 'App Promotion', description: 'Increase downloads and usage of your app through targeted campaigns and advertising.' } // marketing objective: app promotion
  ],
  brand_ads_location: [
    { id: 'company_location', value: 'Default Company location' }, // facebook ads
    // { id: 'other_location', value: 'Other location' } // instagram
  ],
  selection_ss_al: [
    { id: 'smartscan_selection', value: 'Get your ads prediction' },
    { id: 'adslibrary_selection', value: 'Regenerate Winning Ads' },
  ],
  smartscan: [
    // { id: 'smartscan_image', value: 'Upload product image' },
    { id: 'smartscan_desc', value: 'Smart Scan description' },
  ],
  smartscan_graph: [
    { id: 'smartscan_graph', value: <a href="https://staging.omninexus.io/smart-scan" target="_blank">Smart scan</a> },
  ],
  campaign_creation_options: [
    { id: 'adslibrary', value: 'Winning Ads - ads library (5 results)' },
    { id: 'create ads', value: 'Create your own ads' }
  ],
  no_adslibrary_options: [
    { id: 'smart scan', value: 'Smart Scan' },
    { id: 'create ads', value: 'Create your own ads' }
  ],
  regenerate_copy: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' }
  ],
  regenerate_image: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' }
  ],
  generate_adslib_image: [
    { id: 'yes', value: 'Yes' },
    { id: 'no', value: 'No' }
  ],
  initial_copywriting_strategy: [
    { id: 'driving conversion', value: 'Driving conversions', description: "Advertise with direct conversion focused approach by focusing on your product and also the problem." },
    { id: 'consultancy', value: 'Consultancy', description: "Offering free consultations encourages business owners to explore customized UI/UX solutions, boosting lead generation." },
    { id: 'time limited offer', value: 'Time limited offer', description: "Creating urgency with a limited-time offer prompts business owners to quickly secure premium UI/UX design services, driving immediate leads." },
    { id: 'discounts', value: 'Discounts', description: "Attracts business owners by offering affordable, high-quality UI/UX design, boosting lead conversions." },
    { id: 'custom strategy', value: 'Custom strategy', description: "" },
  ],
};

// export const copywritingStyleOptions = [
//   { id: '1', value: 'AIDA', description: 'Attention, Interest, Desire, Action' },
//   { id: '2', value: 'PAS', description: 'Problem, Agitate, Solution' },
//   { id: '3', value: '4Cs', description: 'Clear, Concise, Compelling, Credible' },
//   { id: '4', value: 'FAB', description: 'Features, Advantages, Benefits' },
//   { id: '5', value: 'ACC', description: 'Awareness, Comprehension, Conversion' },
//   { id: '6', value: 'SLAP', description: 'Stop, Look, Act, Purchase' },
// ];

