//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : GoogleLoginButton.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240705     Jecyline     create GoogleLoginButton.jsx
//* 20240717     Jecyline     amend handleLoginSuccess(), get REACT_APP_GOOGLE_CLIENT_ID from env, change plugin to react-oauth/google
//
//***************************************************************************/
import React,  { useEffect, useState }  from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { googleicon } from "../Routes/ImagePath";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { api_url } from "../base_urls";

const GoogleLoginButton = () => {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState('');

  const responseGoogle = async (credentialResponse) => {
    const { credential } = credentialResponse;
    const response = await axios.post(api_url + '/auth/google/callback', { id_token: credential });
    console.log('Google login response:', response);
    const { access_token, token_type, expires_in, user } = response.data;


    if (user.status === 2) { // first time login user
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('token_type', token_type);
      localStorage.setItem('expires_in', expires_in);
      localStorage.setItem('refresh_token', user.refresh_token);
      localStorage.setItem('feedbackModalShown', 'false');
      navigate('/first-time-login');
    } else if (user.status === 3) { // active user
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('token_type', token_type);
      localStorage.setItem('expires_in', expires_in);
      localStorage.setItem('refresh_token', user.refresh_token);
      localStorage.setItem('feedbackModalShown', 'false');
      navigate('/dashboard');
    } else if (user.status === 1) { // active user
      const redirect = 'first-time-login';
      navigate('/otp', { state: { email: user.email , redirect: redirect } });
      console.log(user);
      const email = user.email;
      try {
        const response = await fetch(api_url + '/resend-otp', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email , redirect})
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to resend otp');
        } else {
          const responseData = await response.json();
          // console.log(responseData);

          setMessage(responseData.message);
          setTimeout(() => setMessage(''), 10000); // Clear message after 5 seconds
        }

      } catch (error) {
        console.error('Error:', error.message);
      }
    }

  };
  
  
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleLogin
        onSuccess={responseGoogle}
        onFailure={(response) => console.log('Login Failed:', response)}
        isSignedIn={true}
        style={{ display: 'none' }} // Hide the default button
        >
        {({ onClick }) => (
          <button className="account-icon-button" onClick={onClick}>
            <img src={googleicon} alt="Google" className="account-img-icon"/>
            Login with Google
          </button>
        )}
      </GoogleLogin>
    </GoogleOAuthProvider>
  );
  
  };
  

export default GoogleLoginButton;


